<template>
  <div class="card p-shadow-6">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <h1>Minhas Avaliações de Formações</h1>
    <Divider />
    <div class="p-grid p-col-12 p-lg-12">
      <div
        class="p-field p-col-12 p-md-3"
        v-for="training in trainingList"
        :key="training.id"
      >
        <h3
          v-bind:class="[
            { 'text-red': !Number.isInteger(training.evaluation) }
          ]"
        >
          {{ training.name }}
        </h3>
        <Rating
          v-model="training.evaluation"
          @input="createUpdateUserTrainingEvaluation(training)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import trainingService from "../services/training.service";
export default {
  name: "TrainingActions",
  data() {
    return {
      loading: true,
      trainingList: []
    };
  },
  async created() {
    this.loading = true;
    await this.getTrainings();
    this.loading = false;
  },
  methods: {
    async getTrainings() {
      return trainingService
        .userTrainingsEvaluation(this.$store.state.auth.user.id)
        .then(response => {
          return (this.trainingList = response);
        });
    },
    createUpdateUserTrainingEvaluation(training) {
      if (training.evaluation == null) {
        training.evaluation = 0;
      }

      let params = {
        value: training.evaluation
      };
      trainingService
        .setTrainingsEvaluation(
          training.id,
          this.$store.state.auth.user.id,
          params
        )
        .then(response => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro ao gravar",
              detail: "Ocorreu um erro ao gravar",
              life: 3000
            });
          }
        });
    }
  }
};
</script>
<style>
.text-red {
  color: red;
}
</style>
